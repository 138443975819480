.container {
    font-family: Arial, Helvetica, sans-serif;
    max-width: 1000px;
    margin: 0 auto;
    padding: 20px;
    color: rgb(183,182,180);
  }
  
  .header {
    text-align: center;
    background-color: #360707;
    color: white;
    padding: 20px 0;
    border-radius: 8px;
  }
  
  .header h1 {
    margin: 0;
    color: rgb(250,250,250);
    font-size: 2.5em;
    font-family: Arial, Helvetica, sans-serif;
  }
  
  .header p {
    margin: 10px 0 0;
    font-size: 1.2em;
  }
  
  .content {
    margin: 20px 0;
    font-family: Arial, Helvetica, sans-serif;
    color: rgb(183,182,180);
  }
  
  .content h2 {
    color: rgb(250,250,250);
    font-size: 1.8em;
    font-family: Arial, Helvetica, sans-serif  }
  
  .content p, .content ul {
    font-size: 1.1em;
    line-height: 1.6;
  }
  
  .content ul {
    list-style-type: disc;
    margin-left: 20px;
  }
  
  .footer {
    text-align: center;
    margin-top: 20px;
    padding: 10px 0;
    background-color: #f1f1f1;
    border-radius: 8px;
  }

  