.dayDropdownBox{
    flex:2;
    display: flex;
    flex-direction:row;
    margin:auto; 
    margin-left:5px;
    margin-right:5px; 
      
}

.monthDropdownBox{
    flex:4;
    display: flex;
    flex-direction:row;
    margin:auto;  
     
}

.yearDropdownBox{
    flex:3;
    display: flex;
    flex-direction:row;
    margin:auto;    
}



.dateDropdownOuterBox{
    flex:1;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    
}


select{
    flex: 1;
    font-size: 16px; /* Updated font size */
    font-family: Arial, Helvetica, sans-serif;
    font-weight:bold;
    text-align: center; /* Centers text within each <option> */
    padding: 3px; /* Adds some padding inside the dropdown */
    margin: auto;
    border-radius:5px;
    width: 100%; /* Ensures select fills the container */ 
    background-color: rgb(250,250,248);  
    
}

option{
    text-align: center; /* Ensures text in options is also centered */
    background-color: rgb(250,250,248); /* Ensures the background of options is white */
    font-family: Arial, Helvetica, sans-serif;
    font-weight:bold;
    
}