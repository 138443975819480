body {
  margin: 0;
  min-height:100%;
  min-width:100%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color:rgb(18,17,16);
  color:rgb(183,182,180);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
h1{
  text-align:center
}
h2{
  text-align:center
}
h3{
  text-align:center
}
button{
  background-color: rgb(18,17,16);
  color: #535bf2;
  border-width:1px;
  border-style:solid;
  border-color: #535bf2;
  transition-duration: 0.2s;
}




